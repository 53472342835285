/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import { graphql } from "gatsby";
import { AppContext } from "~context/AppContext";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const StaticPage = ({ data, location }) => {
  const { darkMode } = useContext(AppContext);

  const { frontmatter } = data.markdownRemark;

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout
        className={`static-page w-full relative transition-background bg-${
          darkMode ? `black` : `white`
        } pb-v4 xs:overflow-x-hidden text-${darkMode ? `white` : `black`}`}
      >
        <section className="w-full relative block pt-v14 xs:pt-v2 mb-v12">
          <article className="grid xs:mt-v12">
            <h1 className="animation-appear-down-slow animation-delay-2 grid-end-24 pl-v4 pr-v4 f1 text-center">
              {frontmatter.title}
            </h1>

            {frontmatter?.content !== `` && (
              <div
                className="animation-appear-down-slow animation-delay-3 static-page__content grid-end-22 xs:grid-end-24 grid-start-2 xs:grid-start-1 mt-v3 pl-v8 xs:pl-v1 pr-v8 xs:pr-v1"
                dangerouslySetInnerHTML={{ __html: frontmatter.content }}
              ></div>
            )}
          </article>
        </section>
      </Layout>

      <Footer />
    </>
  );
};

export default StaticPage;

export const query = graphql`
  query StaticPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        content
        seoDescription
        seoKeywords
      }
    }
  }
`;
